import React, { useState } from "react";
import { GamesInfo } from "../../Constants/Games";
import { Prism as SyntaxHighlighter } from "react-syntax-highlighter";
import { Connect4ProblemStatement } from "../../Constants/Connect4";

const CodeTabs = ({ width, game }) => {
  const [activeTab, setActiveTab] = useState("gameSummary");

  return (
    <div
      className="flex flex-col bg-surfaceContain-light dark:bg-surfaceContain-dark"
      style={{ width: width, height: "calc(100vh - 81px)" }}
    >
      <h1 className="pt-6 mb-6 text-2xl font-bold text-center text-onSurface-light dark:text-onSurface-dark">
        {GamesInfo[game].title}
      </h1>

      {/* Tab Buttons */}
      <div className="flex px-6 border-b">
        <button
          className={`w-1/2 py-2 text-center ${
            activeTab === "gameSummary"
              ? "border-b-2 border-primary text-primary"
              : "text-gray-500"
          }`}
          onClick={() => {
            setActiveTab("gameSummary");
          }}
        >
          Summary
        </button>
        <button
          className={`w-1/2 py-2 text-center ${
            activeTab === "sourceCode"
              ? "border-b-2 border-primary text-primary"
              : "text-gray-500"
          }`}
          onClick={() => {
            setActiveTab("sourceCode");
          }}
        >
          Source Code
        </button>
      </div>

      {/* Tab Content */}
      <div
        className="flex-grow p-6 overflow-y-auto"
        style={{ maxHeight: "calc(100% - 120px)" }}
      >
        {activeTab === "gameSummary" && (
          <div className="space-y-4">
            <section className="pb-4">
              <h2 className="text-lg font-bold text-onSurface-light dark:text-onSurface-dark">
                Goal of the game:
              </h2>
              <p className="text-onSurfaceSecond-light dark:text-onSurfaceSecond-dark">
                {GamesInfo[game].goal}
              </p>
            </section>
            <section className="pb-4">
              <h2 className="text-lg font-bold text-onSurface-light dark:text-onSurface-dark">
                What to implement:
              </h2>
              <div className="whitespace-pre-wrap text-onSurfaceSecond-light dark:text-onSurfaceSecond-dark">
                {(() => {
                  const ProblemStatement = GamesInfo[game].problemStatement;
                  return <ProblemStatement />;
                })()}
              </div>
            </section>
            <section className="pb-4">
              <h2 className="text-lg font-bold text-onSurface-light dark:text-onSurface-dark">
                Constraints:
              </h2>
              <div className="whitespace-pre-wrap">
                <ul className="pl-5 list-disc text-onSurface-light dark:text-onSurface-dark">
                  <li>Time to instantiate class: 2 seconds</li>
                  <li>Time per move: 2 seconds</li>
                </ul>
              </div>
            </section>
          </div>
        )}
        {activeTab === "sourceCode" && (
          <SyntaxHighlighter
            language="python"
            wrapLines={true}
            wrapLongLines={true}
            codeTagProps={{ style: { fontFamily: "Menlo" } }}
            customStyle={{
              margin: 0,
              padding: "1em",
              borderRadius: "8px",
              maxHeight: "100%",
              overflow: "auto",
            }}
          >
            {GamesInfo[game].gameCode}
          </SyntaxHighlighter>
        )}
      </div>
    </div>
  );
};

export default CodeTabs;
