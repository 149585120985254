import React from "react";

const ModelInfo = ({ Rating, Name, Creator, DateCreated }) => {
  return (
    <div className="flex flex-col sm:flex-row gap-2 rounded-lg justify-between w-full p-6 bg-surfaceContain-light dark:bg-surfaceContain-dark border-[1px] border-border-light dark:border-border-dark">
      <div className="flex flex-col sm:flex-row gap-6">
        <div className="flex flex-col gap-1">
          <p className="text-sm text-onSurfaceSecond-light dark:text-onSurfaceSecond-dark">
            Rating
          </p>
          <p className="text-xl font-medium text-onSurface-light dark:text-onSurface-dark">
            {Rating || "N/A"}
          </p>
        </div>
        <div className="flex flex-col gap-1">
          <p className="text-sm text-onSurfaceSecond-light dark:text-onSurfaceSecond-dark">
            Name
          </p>
          <p className="text-xl font-medium text-onSurface-light dark:text-onSurface-dark">
            {Name || "No name available"}
          </p>
        </div>
        <div className="flex flex-col gap-1">
          <p className="text-sm text-onSurfaceSecond-light dark:text-onSurfaceSecond-dark">
            Creator
          </p>
          <p className="text-xl font-medium text-onSurface-light dark:text-onSurface-dark">
            {Creator || "No user available"}
          </p>
        </div>
      </div>
      <div className="flex flex-col gap-1 mt-4 sm:mt-0">
        <p className="text-sm text-onSurfaceSecond-light dark:text-onSurfaceSecond-dark">
          Date Created
        </p>
        <p className="text-xl font-medium text-onSurface-light dark:text-onSurface-dark">
          {DateCreated || "N/A"}
        </p>
      </div>
    </div>
  );
};

export default ModelInfo;
