import React from "react";
import './Loader.css';

function Loader({ size = "90px" }) {
    return (
        <div className="loader-container">
            <div className="loader" style={{ width: size, height: size }}></div>
        </div>
    );
}

export default Loader;