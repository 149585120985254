import React, { useState, useEffect } from "react";
import Editor from "@monaco-editor/react";
import { GamesInfo } from "../../Constants/Games";
import SubmitModal from "../SubmitModal/SubmitModal";

function TextEditor({ user, width, game, name, icon, setReload }) {

  const [isDarkMode, setIsDarkMode] = useState(false);

  useEffect(() => {
    // Check if dark mode is active by inspecting the body or any element with dark mode class
    const isDark = document.documentElement.classList.contains("dark");
    setIsDarkMode(isDark);
  }, []);

  const initialFiles = {
    name: "player.py",
    defaultLanguage: "python",
    default: GamesInfo[game].starterCode,
  };

  const [gameCode, setGameCode] = useState(initialFiles.default);
  const [modelWeights, setModelWeights] = useState("");

  const [fileName, setFileName] = useState("");
  const [fileType, setFileType] = useState("");

  const [submitOpen, setSubmitOpen] = useState(false);

  const handleSubmitClose = () => {
    setSubmitOpen(false);
  }

  const handleFileUpload = (event) => {
    const file = event.target.files[0];
    if (file) {
      const parts = file.name.split(".");
      setFileName(parts[0]);
      setFileType(parts[1]);
      const reader = new FileReader();

      reader.onload = () => {
        const binaryData = reader.result;
        const base64String = btoa(
          new Uint8Array(binaryData).reduce(
            (data, byte) => data + String.fromCharCode(byte),
            ""
          )
        );

        setModelWeights(base64String);
      };

      reader.readAsArrayBuffer(file);
    }
  };

  return (
    <div>
      <div className="editor-container">
        <div className="editor-wrapper">
          <Editor
            lineNumbers="off"
            glyphMargin={false}
            folding={false}
            width={width}
            height="calc(100vh - 81px - 110px)"
            path={initialFiles.name}
            theme={isDarkMode ? "vs-dark" : "vs-light"}
            defaultLanguage={initialFiles.defaultLanguage}
            defaultValue={initialFiles.default}
            onChange={(value) => setGameCode(value)}
            options={{
              minimap: {
                enabled: false,
              },
              fontSize: 16,
            }}
          />

          <div className="flex flex-row items-center justify-between p-4 mt-4">
            <div>
              {/* TODO: UNCOMMENT ONCE FIXED */}
              {/* <label className="">
                <p>Upload Model Weights</p>
                <input
                  type="file"
                  onChange={handleFileUpload}
                  accept=".h5, .pt, .pth, .pkl, .bin"
                />
              </label> */}
            </div>
            <div>
              <button
                className="w-full py-2 px-4 rounded-md transition-colors duration-200 bg-[#F395BE] hover:bg-[#e879a9]"
                onClick={() => { setSubmitOpen(true) }}
              >
                Submit
              </button>
            </div>
          </div>
        </div>

        {submitOpen && 
        <SubmitModal user={user} game={game} gameCode={gameCode} modelWeights={modelWeights} fileName={fileName} fileType={fileType} setReload={setReload} backToCode={handleSubmitClose} isDarkMode={isDarkMode}/>}
      </div>
    </div>
  );
}

export default TextEditor;
