import React, { useState, useEffect } from 'react';
import { Prism as SyntaxHighlighter } from 'react-syntax-highlighter';

const FillerBgMap = {
	1: 'bg-red-500',
	2: 'bg-orange-500',
	3: 'bg-yellow-400',
	4: 'bg-green-600',
	5: 'bg-blue-500',
	6: 'bg-purple-500',
	7: 'bg-teal-500',
	8: 'bg-pink-500',
}

const FillerBorderMap = {
	1: 'border-red-500',
	2: 'border-orange-500',
	3: 'border-yellow-400',
	4: 'border-green-600',
	5: 'border-blue-500',
	6: 'border-purple-500',
	7: 'border-teal-500',
	8: 'border-pink-500',
}

export const FillerGameView = ({ gameData, setShowWinner }) => {
 
	const moves = JSON.parse(gameData.moves);

	const [currentMove, setCurrentMove] = useState(0);
	const [showAnimation, setShowAnimation] = useState(true);

	const board = moves[currentMove];

	useEffect(() => {
		if (currentMove < moves.length && showAnimation) {
			const timeout = setTimeout(() => {
				if (currentMove < moves.length - 1) {
					setCurrentMove(currentMove + 1);
				} else {
					setShowAnimation(false);
					setShowWinner(true);
				}
			}, 1000);

			return () => clearTimeout(timeout);
		}
	}, [currentMove, showAnimation]);

	return (
		<div className="flex flex-col justify-center">
			<div className="grid flex justify-center items-center">
				{board.map((row, rowIndex) => (
					<div key={rowIndex} className="flex">
						{row.map((cell, colIndex) => (
							<div
								key={colIndex}
								className={`relative w-12 h-12 flex justify-center items-center border border-gray-300 transform transition-all duration-500 ease-in-out ${
									`${FillerBgMap[cell]} ${FillerBorderMap[cell]}`
								}`}
							>
							</div>
						))}
					</div>
				))}
			</div>
			{!showAnimation &&
				<div className="flex justify-center mt-4">
					<button
						className="px-4 py-2 bg-gray-300 mr-2 rounded disabled:opacity-50"
						onClick={() => {
							if (currentMove === moves.length - 1) {
								setShowWinner(false);
							}
							setCurrentMove(currentMove - 1);
						}}
						disabled={currentMove === 0}
					>
						Previous
					</button>
					<button
						className="px-4 py-2 bg-gray-300 rounded disabled:opacity-50"
						onClick={() => {
							if (currentMove + 1 === moves.length - 1) {
								setShowWinner(true);
							}
							setCurrentMove(currentMove + 1);
						}}
						disabled={currentMove === moves.length - 1}
					>
						Next
					</button>
				</div>
			}

			{currentMove < moves.length - 1 ?
				<div className="flex justify-center mt-4">
					<button
						className="px-4 py-2 bg-gray-300 mr-2 rounded disabled:opacity-50"
						onClick={() => setShowAnimation(!showAnimation)}
					>
						{showAnimation ? 'Pause Autoplay' : 'Autoplay'}
					</button>
				</div> :
				<div className="flex justify-center mt-4">
					<button
						className="px-4 py-2 bg-gray-300 mr-2 rounded disabled:opacity-50"
						onClick={() => {
							setCurrentMove(0);
							if (moves.length !== 1) {
								setShowWinner(false);
							}
						}}
					>
						Replay
					</button>
				</div>
			}
		</div>
	);
};

export const FillerDescription = `Capture more cells than your opponent by spreading your color across the grid. Choose colors wisely and expand your territory!`

export const FillerProblemStatement = () => {
	const gameStateExample = `gameState = [
        [1, 3, 4, 1, 2, 6, 4, 1],
        [4, 2, 3, 5, 1, 4, 2, 3],
        [1, 6, 4, 3, 2, 1, 5, 4],
        [3, 1, 2, 6, 4, 3, 2, 6],
        [5, 2, 1, 4, 3, 2, 4, 1],
        [2, 5, 4, 1, 6, 5, 3, 4],
        [3, 4, 1, 2, 5, 6, 1, 2],
        [1, 4, 2, 3, 2, 5, 6, 4]
    ]`;

	const gameStateAfterMove = `gameState = [
        [1, 3, 4, 1, 2, 6, 4, 1],
        [4, 2, 3, 5, 1, 4, 2, 3],
        [1, 6, 4, 3, 2, 1, 5, 4],
        [3, 1, 2, 6, 4, 3, 2, 6],
        [5, 2, 1, 4, 3, 2, 4, 1],
        [2, 5, 4, 1, 6, 5, 3, 4],
        [3, 4, 1, 2, 5, 6, 1, 2],
        [1, 4, 2, 3, 2, 5, 5, 5]
    ]`;

	return (
		<div className="space-y-4">
			<p>
				You must implement a class with a method makeMove() that takes in the current state of the board and returns a new color.
			</p>
			<p>
				A gameState is represented as an 8 row by 8 column 2D array of integers, where each number corresponds to a different color (1-8). Player1 starts at the top-right corner (0,7) and Player2 starts at the bottom-left corner (7,0).
			</p>
			<p>Here is an example of a starting gameState:</p>
			<SyntaxHighlighter language="python">
				{gameStateExample}
			</SyntaxHighlighter>
			<p>
				Your makeMove() function should return a color (1-8) that the player wants to switch to. After player1 chooses color 5, the gameState becomes:
			</p>
			<SyntaxHighlighter language="python">
				{gameStateAfterMove}
			</SyntaxHighlighter>
			<p>And player1 expands their territory!</p>
		</div>
	);
};

export const FillerStarterCode = `# Fill in the makeMove function
class Player:

    def __init__(self, playerOne: bool):
        pass

    def makeMove(self, gameState: list[list[int]]) -> int:
        return 0
`

export const FillerGameCode = `# the filler class that will be used to judge your code
import time
import numpy as np
from collections import defaultdict

class Filler(object):
	def __init__(self, player1, player2, render):
		self.p1 = player1
		self.p2 = player2
		self.p1set = set([(0,7)])
		self.p2set = set([(7,0)])
		self.turn = 1
		self.dirs = [(0,1), (1,0), (0,-1), (-1,0)]
		self.color_map = {1 : '🟥', 2 : '🟧', 3 : '🟨', 4 : '🟩', 5 : '🟦', 6 : '🟪', 7 : '⬜️', 8 : '🟫'}
		self.board = [[0] * 8 for _ in range(8)]
		for i in range(8):
			for j in range(8):
				self.board[i][j] = np.random.choice(np.arange(1,9))
		self.p1c = self.board[0][7] 
		self.p2c = self.board[7][0]
		self.render = render
		if render:
			for i in range(9):print()
		self.winner = 0

		self.moves = []
		self.init_game()

	def _detect_win(self):
		colors = defaultdict(int)
		for i in range(len(self.board)):
			for j in range(len(self.board[0])):
				colors[self.board[i][j]] += 1
		if len(colors) > 2:
			return 0
		if colors[self.p1c] > colors[self.p2c]:
			return 1
		elif colors[self.p1c] < colors[self.p2c]:
			return 2
		return -1

	def _update_board(self, player_set, new_color):
		vis = set()
		def dfs(i, j):
			if -1 in {i, j} or 8 in {i, j} or (i,j) in vis:
				return 
			if self.board[i][j] != new_color and (i,j) not in player_set:
				return
			if self.board[i][j] == new_color and (i,j) not in player_set:
				player_set.add((i,j))
			elif (i,j) in player_set:
				self.board[i][j] = new_color
			vis.add((i,j))
			for dx, dy in self.dirs:
				dfs(i + dx, j + dy)
		for i, j in player_set.copy():
			dfs(i,j)

	def _play_move(self):
		color = None
		if self.turn == 1:
			color = self.p1(self.board, 1)
			if color == None or color == self.p2c or color < 1 or color > 8:
				self.winner = 2
				return
			self.p1c = color
			self._update_board(self.p1set, color)
		else:
			color = self.p2(self.board, 2)
			if color == None or color == self.p1c or color < 1 or color > 8:
				self.winner = 1
				return
			self.p2c = color
			self._update_board(self.p2set, color)
		self.turn = 3 - self.turn 
		return self._detect_win()

	def _render(self):

		# this will clear the screen 
		LINE_UP = '\x1b[1A'
		LINE_CLEAR = '\x1b[2K'
		for i in range(9):
			print(LINE_UP, end=LINE_CLEAR)
		for i in self.board:
			arr = []
			for color in i:
				arr.append(self.color_map[color])
			print("".join(arr))
		print()

	def _json_render(self):
		b = []

		for i in self.board:
			arr = []
			for color in i:
				arr.append(self.color_map[color])
			b.append(" ".join(arr))

		self.moves.append("\n".join(b))

	def init_game(self):
		win = 0
		while not win:
			self._play_move()
			if self.render: 
				self._render()
				time.sleep(0.3)
			else:
				self._json_render()
			win = self._detect_win() | self.winner
		if win == 1:
			self.winner = 1
			if self.render: print(self.color_map[self.p1c], "WINS")
		elif win == 2:
			self.winner = 2
			if self.render: print(self.color_map[self.p2c], "WINS")

`