import React, { useState } from "react";
import { encode as base64_encode } from "base-64";
import Loader from "../Loader/Loader";
import { useNavigate } from "react-router-dom";

function SubmitModal({ 
    user, 
    game, 
    gameCode, 
    modelWeights, 
    fileName, 
    fileType, 
    setReload, 
    backToCode, 
    isDarkMode 
}) {
    const navigate = useNavigate();
    const [errorTxt, setErrorTxt] = useState("");
    const [submitted, setSubmitted] = useState(false);
    const [rating, setRating] = useState(0);
    const [modelId, setModelId] = useState("");
    const [botName, setBotName] = useState("");
    const [loading, setLoading] = useState(false);

    const isAlphanumeric = (str) => /^[a-zA-Z0-9]+$/.test(str);

    const sendJsonData = async () => {
        setLoading(true);
        try {
            const jsonData = {
                game: game,
                name: botName,
                files: modelWeights
                    ? [
                        {
                            name: "player",
                            contents: base64_encode(gameCode),
                            format: "py",
                        },
                        {
                            name: fileName,
                            contents: modelWeights,
                            format: fileType,
                        },
                    ]
                    : [
                        {
                            name: "player",
                            contents: base64_encode(gameCode),
                            format: "py",
                        },
                    ],
            };
        
            const token = await user.getIdToken();

            const res = await fetch(
                process.env.REACT_APP_endpointURL + "/model/submit",
                {
                    method: "POST",
                    headers: {
                        Authorization: token,
                        "Content-Type": "application/json",
                    },
                    body: JSON.stringify(jsonData),
                }
            );
            if (!res.ok) {
                throw new Error(`HTTP error! status: ${res.status}`);
            }
            const result = await res.json();
            if (result.invalidSubmission) {
                setErrorTxt("There is an error in your code that is preventing your model from running...");
            }
            setRating(result.rating);
            setModelId(result.modelId);
        } catch (error) {
            console.error("Error:", error);
            setErrorTxt("There was an error in your submission.");
        } finally {
            setLoading(false);
            setSubmitted(true);
            setBotName("");
            setReload((prev) => !prev);
        }
    };

    // Dynamic styles based on isDarkMode prop
    const modalStyles = isDarkMode 
        ? "bg-gray-800 text-white"
        : "bg-white text-gray-800";

    const buttonStyles = isDarkMode 
        ? "bg-pink-600 hover:bg-pink-500 text-white" 
        : "bg-[#F395BE] hover:bg-[#e879a9] text-gray-800";

    const errorTextColor = isDarkMode ? "text-red-400" : "text-red-500";

    return (
        <div>
            <div className={`fixed inset-0 z-50 flex items-center justify-center ${isDarkMode ? 'bg-black bg-opacity-70' : 'bg-gray-100 bg-opacity-90'}`}>
                <div className={`relative w-full max-w-md p-6 rounded-lg shadow-lg ${modalStyles}`}>
                    <svg
                        onClick={() => { backToCode() }}
                        xmlns="http://www.w3.org/2000/svg"
                        width="16"
                        height="16"
                        viewBox="0 0 30 30"
                        className={`absolute cursor-pointer top-4 right-4 ${isDarkMode ? 'text-white' : 'text-gray-800'}`}
                    >
                        <path d="M 7 4 C 6.744125 4 6.4879687 4.0974687 6.2929688 4.2929688 L 4.2929688 6.2929688 C 3.9019687 6.6839688 3.9019687 7.3170313 4.2929688 7.7070312 L 11.585938 15 L 4.2929688 22.292969 C 3.9019687 22.683969 3.9019687 23.317031 4.2929688 23.707031 L 6.2929688 25.707031 C 6.6839688 26.098031 7.3170313 26.098031 7.7070312 25.707031 L 15 18.414062 L 22.292969 25.707031 C 22.682969 26.098031 23.317031 26.098031 23.707031 25.707031 L 25.707031 23.707031 C 26.098031 23.316031 26.098031 22.682969 25.707031 22.292969 L 18.414062 15 L 25.707031 7.7070312 C 26.098031 7.3170312 26.098031 6.6829688 25.707031 6.2929688 L 23.707031 4.2929688 C 23.316031 3.9019687 22.682969 3.9019687 22.292969 4.2929688 L 15 11.585938 L 7.7070312 4.2929688 C 7.5115312 4.0974687 7.255875 4 7 4 z" />
                    </svg>
                    {loading ? (
                        <div className="flex justify-center p-10">
                            <Loader size="70px" />
                        </div>
                    ) : (
                        <div className="flex items-center justify-center h-full">
                        {submitted ? (
                            <div className="text-center">
                                {errorTxt ? (
                                    <div className={`mt-2 ${errorTextColor}`}>{errorTxt}</div>
                                ) : (
                                    <div>
                                        <div className="text-2xl font-semibold">
                                            Your model has been submitted!
                                        </div>
                                        <div className={`p-4 mt-6 text-2xl font-medium ${isDarkMode ? 'bg-pink-700 border-pink-600' : 'bg-pink-100 border-pink-300'} border-4 rounded-md`}>
                                            Rating: <span className="font-bold">{rating}</span>
                                        </div>
                                        <div className="flex justify-between gap-2 mt-6">
                                            <button
                                                className={`py-2 px-4 rounded-md transition-colors duration-200 ${buttonStyles}`}
                                                onClick={() => { backToCode() }}
                                            >
                                                Back to Code
                                            </button>
                                            <button
                                                className={`py-2 px-4 rounded-md transition-colors duration-200 ${buttonStyles}`}
                                                onClick={() => {
                                                    navigate("/battle", { state: { initialGame: game } });
                                                }}
                                            >
                                                Battle
                                            </button>
                                            <button
                                                className={`py-2 px-4 rounded-md transition-colors duration-200 ${buttonStyles}`}
                                                onClick={() => {
                                                    navigate(`/submission/${game}/${modelId}`);
                                                }}
                                            >
                                                Model Details
                                            </button>
                                        </div>
                                    </div>
                                )}
                            </div>
                        ) : (
                            <div>
                                <label
                                    htmlFor="name"
                                    className={`block mb-2 font-medium ${isDarkMode ? 'text-gray-300' : 'text-gray-800'} text-md`}
                                >
                                    Name your bot
                                </label>
                                <input
                                    type="text"
                                    id="name"
                                    className={`w-full p-2 ${isDarkMode ? 'bg-gray-700 border-gray-600 text-white' : 'bg-white border-gray-300 text-gray-800'} rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-blue-500`}
                                    placeholder="Bot name"
                                    onChange={(e) => setBotName(e.target.value)}
                                />
                                <button
                                    className={`w-full py-2 px-4 rounded-md transition-colors duration-200 mt-4 ${!botName || botName.length > 10 || !isAlphanumeric(botName) ? "bg-gray-600 cursor-not-allowed text-gray-400" : buttonStyles}`}
                                    onClick={() => sendJsonData()}
                                    disabled={!botName || botName.length > 10 || !isAlphanumeric(botName)}
                                >
                                    Submit
                                </button>
                                {botName.length > 10 && (
                                    <div className={`mt-2 ${errorTextColor}`}>
                                        Name too long. Please shorten.
                                    </div>
                                )}
                                {botName && !isAlphanumeric(botName) && (
                                    <div className={`mt-2 ${errorTextColor}`}>
                                        Names should only include letters or numbers please.
                                    </div>
                                )}
                            </div>
                        )}
                        </div>
                    )}
                </div>
            </div>
        </div>
    );
}

export default SubmitModal;
