import React from "react";
import { GamesInfo } from "../../Constants/Games";

function ModelFilters({
  selectedFilters = [],
  onFilterSelect,
  singleSelect = false,
}) {
  const handleFilterClick = (game) => {
    if (singleSelect) {
      onFilterSelect(game);
    } else {
      if (selectedFilters.includes(game)) {
        onFilterSelect(selectedFilters.filter((filter) => filter !== game));
      } else {
        onFilterSelect([...selectedFilters, game]);
      }
    }
  };

  return (
    <div className="flex flex-wrap gap-2 bg-surface-light dark:bg-surface-dark">
      {Object.keys(GamesInfo).map((game) => (
        <div
          key={game}
          onClick={() => handleFilterClick(game)}
          className={`px-4 py-2 rounded-lg cursor-pointer border-[1px] dark:border-border-dark border-border-light ${
            selectedFilters.includes(game)
              ? "bg-primary text-onSurface"
              : "bg-surfaceContain-light dark:bg-surfaceContain-dark  text-onSurface-light dark:text-onSurface-dark"
          }`}
        >
          {GamesInfo[game].title}
        </div>
      ))}
    </div>
  );
}

export default ModelFilters;
