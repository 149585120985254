import React, { useState } from "react";
import { auth } from "../../Firebase/firebaseAuth";
import { sendPasswordResetEmail } from "../../Firebase/firebaseAuth";

const ForgotPassword = ({ setMode }) => {
    const [email, setEmail] = useState("");
    const [errorTxt, setErrorTxt] = useState("");
    const [passwordReset, setPasswordReset] = useState(false);

    const handlePasswordReset = async (email) => {
        sendPasswordResetEmail(auth, email)
            .then(() => {
                setErrorTxt("");
                setPasswordReset(true);
                setTimeout(navigateToLogin, 3000);
            })
            .catch((error) => {
                console.error("Error:", error);
                if (error.toString().includes("too-many-requests")) {
                    setErrorTxt("Too many password reset requests.");
                } else if (error.toString().includes("invalid-email")) {
                    setErrorTxt("Invalid email.");
                }
            });
    };

    const handleEmailChange = (event) => {
        setEmail(event.target.value);
    };

    const handleResetPasswordBtnClick = () => {
        handlePasswordReset(email);
    };

    const navigateToLogin = () => {
        setPasswordReset(false);
        setErrorTxt("");
        setMode("login");
    };

    const resetPasswordEnabled = email !== "";

    return (
        <div className="login-page flex justify-center items-center w-full h-[calc(100vh-120px)]">
            <div className="login-container w-[500px] bg-surfaceContain-light dark:bg-surfaceContain-dark rounded-lg shadow-md p-6">
            {
                !passwordReset ? (
                    <div className="login-container w-[440px] pb-10">
                        <h2 className="login-title font-bold text-4xl text-center select-none text-onSurface-light dark:text-onSurface-dark">Reset Password</h2>
                        <div className="email-input flex justify-center mt-7">
                            <input
                                type="email"
                                required
                                className="w-full p-2 border rounded-md shadow-sm border-border-light dark:border-border-dark bg-surface-light dark:bg-surface-dark text-onSurface-light dark:text-onSurface-dark focus:outline-none focus:ring-2 focus:ring-primary-light dark:focus:ring-primary-dark focus:border-primary-light dark:focus:border-primary-dark"
                                placeholder="Email"
                                value={email}
                                onChange={handleEmailChange}
                            />
                        </div>
                        {errorTxt && <div className="error-txt text-red-500 text-sm mt-2">{errorTxt}</div>}
                        <div className="login-btn mt-7">
                            <button
                                className={`w-full py-2 rounded-md transition-colors duration-200 bg-primary-light dark:bg-primary-dark hover:bg-primary-darkHover dark:hover:bg-primary-lightHover ${
                                    !resetPasswordEnabled ? "bg-gray-300 cursor-not-allowed" : ""
                                  }`}
                                onClick={handleResetPasswordBtnClick}
                                disabled={!resetPasswordEnabled}
                            >
                                Reset Password
                            </button>
                        </div>
                        <div className="under-txt-container flex justify-between mt-7">
                            <span
                                className="mt-2 text-sm transition duration-100 cursor-pointer select-none under-txt text-onSurfaceSecond-light dark:text-onSurfaceSecond-dark hover:brightness-75"
                                onClick={navigateToLogin}
                            >
                                Back to Login
                            </span>
                        </div>
                    </div>
                ) : (
                    <div className="reset-confirm-txt text-2xl text-green-500 text-center pb-20">
                        Password reset email has been sent to<br /><strong>{email}</strong>.
                        <br /><br />
                        Redirecting to login page...
                    </div>
                )
            }
        </div>
        </div>
    );
};

export default ForgotPassword;
