import React, { useState, useEffect } from "react";
import Chessboard from "chessboardjsx";
import { Chess } from "chess.js";
import { Prism as SyntaxHighlighter } from "react-syntax-highlighter";
import { TbChess, TbChessFilled } from "react-icons/tb";

export const ChessGameView = ({ gameData, setShowWinner }) => {
  const moves = eval(gameData.moves);

  const [game, setGame] = useState(new Chess());
  const [position, setPosition] = useState(game.fen());
  const [currentMove, setCurrentMove] = useState(0);
  const [showAnimation, setShowAnimation] = useState(true);

  useEffect(() => {
    if (currentMove < moves.length && showAnimation) {
      // Automatically move to the next grid after a delay
      const timeout = setTimeout(() => {
        if (currentMove < moves.length - 1) {
          const move = moves[currentMove];
          game.move({
            from: move.slice(0, 2),
            to: move.slice(2, 4),
            promotion: move.length > 4 ? move.slice(4, 5) : "q",
          });
          setPosition(game.fen());
          setCurrentMove(currentMove + 1);
        } else {
          setShowAnimation(false);
          setShowWinner(true);
        }
      }, 1000); // Adjust this delay for speed of animation

      return () => clearTimeout(timeout); // Clean up the timeout on unmount
    }
  }, [currentMove, showAnimation]);

  // Function to handle moving forward one step
  const stepForward = () => {
    if (currentMove < moves.length) {
      const move = moves[currentMove];
      game.move({
        from: move.slice(0, 2),
        to: move.slice(2, 4),
        promotion: move.length > 4 ? move.slice(4, 5) : "q",
      });
      setPosition(game.fen());
      setCurrentMove(currentMove + 1);
    }
  };

  // Function to handle moving backward one step
  const stepBackward = () => {
    if (currentMove > 0) {
      if (currentMove === moves.length - 1) {
        setShowWinner(false);
      }
      game.undo(); // Undo the last move
      setPosition(game.fen()); // Update the board position
      setCurrentMove(currentMove - 1); // Move back to the previous index
    }
  };

  return (
    <div className="flex flex-col items-center justify-center p-4">
      <Chessboard
        width={400}
        position={position}
        lightSquareStyle={styles.lightSquare}
        darkSquareStyle={styles.darkSquare}
      />
      {!showAnimation && (
        <div className="flex justify-center gap-2 mt-4">
          <button
            className="px-4 py-2 bg-gray-300 rounded disabled:opacity-50"
            onClick={stepBackward}
            disabled={currentMove === 0}
          >
            Previous
          </button>
          <button
            className="px-4 py-2 bg-gray-300 rounded disabled:opacity-50"
            onClick={stepForward}
            disabled={currentMove >= moves.length}
          >
            Next
          </button>
        </div>
      )}
      {currentMove < moves.length ? (
        <div className="flex justify-center mt-4">
          <button
            className="px-4 py-2 mr-2 bg-gray-300 rounded disabled:opacity-50"
            onClick={() => setShowAnimation(!showAnimation)}
          >
            {showAnimation ? "Pause Autoplay" : "Autoplay"}
          </button>
        </div>
      ) : (
        <div className="flex justify-center mt-4">
          <button
            className="px-4 py-2 mr-2 bg-gray-300 rounded disabled:opacity-50"
            onClick={() => {
              setCurrentMove(0);
              setGame(new Chess());
              setPosition("start");
              if (moves.length !== 1) {
                setShowWinner(false);
              }
            }}
          >
            Replay
          </button>
        </div>
      )}
    </div>
  );
};

const styles = {
  lightSquare: {
    backgroundColor: "#ffccf9", // Light pink color for the light squares
  },
  darkSquare: {
    backgroundColor: "#ff99c8", // Darker pink color for the dark squares
  },
};

export const ChessVisualMap = {
  1: <TbChess size={70} />,
  2: <TbChessFilled size={70} />,
};

export const ChessDescription = `Checkmate your opponent by trapping their king.`;

export const ChessProblemStatement = () => {
  return (
    <div className="space-y-4">
      <section className="pb-1">
        <p>
          You must implement a class with a method makeMove() that takes in a
          board and returns a move.
        </p>
      </section>
      <section className="pb-1">
        <p>
          The game is built upon the python-chess library, where the gameState
          is represented as a chess.Board() object. You must will be given a
          copy of the chess.Board instance and return a legal move as a
          chess.Move type.
        </p>
      </section>
      <section className="pb-1">
        <h2 className="text-lg font-bold">3rd party imports:</h2>
        <div className="whitespace-pre-wrap">
          <SyntaxHighlighter language="python">
            chess, numpy, pandas, torch, scipy, passlib, xxhash
          </SyntaxHighlighter>
        </div>
      </section>
      <section className="pb-1">
        <h2 className="text-lg font-bold">Constraints:</h2>
        <div className="whitespace-pre-wrap">
          <ul className="pl-5 list-disc">
            <li>Time to instantiate class: 2 seconds</li>
            <li>Time per move: 2 seconds</li>
          </ul>
        </div>
      </section>
    </div>
  );
};

export const ChessStarterCode = `import numpy as np

# See documentation of the python-chess package 
# https://python-chess.readthedocs.io/en/latest/
import chess

# Fill in the makeMove function
class Player:

    def __init__(self, playerOne: bool):
        pass

    def makeMove(self, gameState: chess.Board) -> chess.Move:
        return np.random.choice([i for i in gameState.legal_moves])
`;
export const ChessGameCode = `# the chess class that will be used to judge your code
class Chess:
    def __init__(self):
        self.board = chess.Board()
        self.turn = 1
        self.moves = []

    def detect_win(self):
        if self.board.outcome():
            res = self.board.outcome().result()
            if res == "1-0":
                return 1
            elif res == "0-1":
                return 2
            else:
                return 3
        return 0

    def make_move(self, move):
        if isinstance(move, str): # in case you pass in a uci string
            move = chess.Move.from_uci(move)
        if move not in self.board.legal_moves:
            return False
        self.board.push(move)
        return True
`;
