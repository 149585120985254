import React from "react";
import LoadingBrain from "../../Images/Brains/loading.png";

function Loading() {

  return (
    <div className="flex flex-col gap-4 items-center justify-center h-screen">
        <img src={LoadingBrain} alt="Loading" className="animate-bounce h-40" />
        <p className="text-lg font-semibold text-onSurface-light dark:text-onSurface-dark">Loading, please wait...</p>
    </div>
  );
}

export default Loading;
