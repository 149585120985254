import React, { useState } from "react";
import { Link, useLocation } from "react-router-dom";
import Logo from "../../Images/Logos/brainrot.png";
import { useNavigate } from "react-router-dom";
import { HiMenu, HiX } from "react-icons/hi";
import { emojiMap } from "../../Constants/RandomProfile";

function ResponsiveAppBar({ user, icon }) {
  const location = useLocation();
  const navigate = useNavigate();
  const [menuOpen, setMenuOpen] = useState(false); // State for mobile menu toggle

  // Function to check if the current path matches the link path
  const isActive = (path) => location.pathname === path;

  // Toggle mobile menu
  const toggleMenu = () => {
    setMenuOpen(!menuOpen);
  };

  return (
    <div className="fixed z-10 w-full px-6 sm:px-20 py-4 bg-surface-light dark:bg-surface-dark border-b-[1px] border-border-light dark:border-border-dark">
      <div className="flex items-center justify-between w-full">
        {/* Logo */}
        <Link to="/">
          <img src={Logo} alt="Logo" className="h-9" />
        </Link>

        {/* Mobile Menu Toggle */}
        <div className="flex items-center sm:hidden">
          <button onClick={toggleMenu} aria-label="Toggle Menu">
            {menuOpen ? (
              <HiX className="text-2xl text-onSurface-light dark:text-onSurface-dark" />
            ) : (
              <HiMenu className="text-2xl text-onSurface-light dark:text-onSurface-dark" />
            )}
          </button>
        </div>

        {/* Menu - Hidden on Mobile */}
        <div className="items-center hidden gap-8 sm:flex">
          <Link
            to="/games"
            className={
              isActive("/games")
                ? "text-onSurface-light dark:text-onSurface-dark hover:text-primary-light dark:hover:text-primary-dark"
                : "text-onSurfaceSecond-light dark:text-onSurfaceSecond-dark hover:text-primary-light dark:hover:text-primary-dark"
            }
          >
            Games
          </Link>
          <Link
            to="/battle"
            className={
              isActive("/battle")
                ? "text-onSurface-light dark:text-onSurface-dark hover:text-primary-light dark:hover:text-primary-dark"
                : "text-onSurfaceSecond-light dark:text-onSurfaceSecond-dark hover:text-primary-light dark:hover:text-primary-dark"
            }
          >
            Battle
          </Link>
          <Link
            to="/contest"
            className={
              isActive("/contest")
                ? "text-onSurface-light dark:text-onSurface-dark hover:text-primary-light dark:hover:text-primary-dark"
                : "text-onSurfaceSecond-light dark:text-onSurfaceSecond-dark hover:text-primary-light dark:hover:text-primary-dark"
            }
          >
            Contest
          </Link>

          {!user ? (
            <button
              onClick={() => navigate("/profile")}
              className="flex items-center px-6 py-2 text-sm rounded-full w-fit bg-primary-light dark:bg-primary-dark"
            >
              Login
            </button>
          ) : (
            <Link to="/profile">
              <div className="flex items-center justify-center pt-[6px] bg-primary-light dark:bg-primary-dark rounded-full size-8">
                <p className="text-2xl">{emojiMap[icon]}</p>
              </div>
            </Link>
          )}
        </div>
      </div>

      {/* Mobile Menu - Visible on Mobile */}
      {menuOpen && (
        <div className="flex flex-col mt-4 space-y-4 sm:hidden">
          <Link
            to="/games"
            className={
              isActive("/games")
                ? "text-onSurface-light dark:text-onSurface-dark underline underline-offset-4"
                : "text-onSurfaceSecond-light dark:text-onSurfaceSecond-dark"
            }
            onClick={toggleMenu}
          >
            Games
          </Link>
          <Link
            to="/battle"
            className={
              isActive("/battle")
                ? "text-onSurface-light dark:text-onSurface-dark underline underline-offset-4"
                : "text-onSurfaceSecond-light dark:text-onSurfaceSecond-dark"
            }
            onClick={toggleMenu}
          >
            Battle
          </Link>
          <Link
            to="/contest"
            className={
              isActive("/contest")
                ? "text-onSurface-light dark:text-onSurface-dark underline underline-offset-4"
                : "text-onSurfaceSecond-light dark:text-onSurfaceSecond-dark"
            }
            onClick={toggleMenu}
          >
            Contest
          </Link>

          {!user ? (
            <button
              onClick={() => {
                navigate("/profile");
                toggleMenu();
              }}
              className="flex items-center px-4 py-2 text-sm rounded-lg w-fit bg-primary"
            >
              Login
            </button>
          ) : (
            <Link to="/profile" onClick={toggleMenu}>
              <div className="flex items-center justify-center pt-[6px] bg-primary-light dark:bg-primary-dark rounded-full size-8">
                <p className="text-2xl">{emojiMap[icon]}</p>
              </div>
            </Link>
          )}
        </div>
      )}
    </div>
  );
}

export default ResponsiveAppBar;
