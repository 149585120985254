import React, { useState } from "react";
import { useParams } from "react-router-dom";
import TextEditor from "../../Components/TextEditor/TextEditor";
import CodeTabs from "../../Components/CodeTabs/CodeTabs";
import { GamesInfo } from "../../Constants/Games";
import Building from "../Building/Building";
import NotFound from "../NotFound/NotFound";
import Loader from "../../Components/Loader/Loader";

const Editor = ({ user, name, icon, setReload }) => {
  const [dividerPosition, setDividerPosition] = useState(40);

  const handleMouseDown = (e) => {
    document.addEventListener("mousemove", handleMouseMove);
    document.addEventListener("mouseup", handleMouseUp);
    document.body.classList.add("select-none");
  };

  const handleMouseMove = (e) => {
    const newDividerPosition = (e.clientX / window.innerWidth) * 100;
    if (newDividerPosition > 20 && newDividerPosition < 90) {
      setDividerPosition(newDividerPosition);
    }
  };

  const handleMouseUp = () => {
    document.removeEventListener("mousemove", handleMouseMove);
    document.removeEventListener("mouseup", handleMouseUp);
    document.body.classList.remove("select-none");
  };

  const { gameName } = useParams();

  if (!(gameName.toLowerCase() in GamesInfo)) {
    return <NotFound />;
  }

  if (!GamesInfo[gameName.toLowerCase()].active) {
    return <Building />;
  }

  if (!gameName) {
    return (
      <div className="grid justify-center p-10">
        <Loader />
      </div>
    );
  }

  return (
    <div className="flex">
      <CodeTabs width={`${dividerPosition}vw`} game={gameName.toLowerCase()} />
      <div
        className="w-4 cursor-col-resize bg-surface-light dark:bg-surface-dark"
        onMouseDown={handleMouseDown}
      ></div>
      <TextEditor
        width={`${100 - dividerPosition}vw`}
        user={user}
        game={gameName.toLowerCase()}
        name={name}
        icon={icon}
        setReload={setReload}
      />
    </div>
  );
};

export default Editor;
