import React, { useState, useEffect } from "react";
import ModelInfo from "../../Components/ModelInfo/ModelInfo";
import ProgressGraph from "../../Components/ProgressGraph/ProgressGraph";
import SubmissionCode from "../../Components/SubmissionCode/SubmissionCode";
import RecentBattleCard from "../../Components/RecentBattleCard/RecentBattleCard";
import Loading from "../Loading/Loading";
import { useParams } from "react-router-dom";
import NotFound from "../NotFound/NotFound";
import GameView from "../../Components/GameView/GameView";
import { GamesInfo } from "../../Constants/Games";
import { decode as base64_decode } from "base-64";

function SubmissionPage() {
  const { gameName, modelId } = useParams();

  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);

  const [model, setModel] = useState(null);
  const [history, setHistory] = useState([]);

  const [showBattle, setShowBattle] = useState(null);

  useEffect(() => {
    const getModelInfo = async () => {
      try {
        const info_res = await fetch(
          process.env.REACT_APP_endpointURL + "/model/content",
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify({
              game: gameName,
              modelId: modelId,
            }),
          }
        );
        if (!info_res.ok) {
          throw new Error(`HTTP error! status: ${info_res.status}`);
        }
        const info = await info_res.json();
        setModel(info);

        const history_res = await fetch(
          process.env.REACT_APP_endpointURL + "/model/history",
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify({
              game: gameName,
              modelId: modelId,
            }),
          }
        );
        if (!history_res.ok) {
          throw new Error(`HTTP error! status: ${history_res.status}`);
        }
        const model_history = await history_res.json();
        setHistory(model_history);
      } catch (error) {
        setError(true);
        console.error("Error:", error);
      } finally {
        setLoading(false);
      }
    };

    getModelInfo();
  }, [modelId]);

  if (showBattle !== null) {
    return (
      <div className="flex flex-col gap-2 items-center">
        <GameView
          gameData={showBattle}
          onClose={() => {
            setShowBattle(null);
          }}
        />
      </div>
    );
  }

  if (loading) {
    return <Loading />;
  }

  if (error) {
    return <NotFound />;
  }

  return (
    <div className="flex flex-col gap-12 px-4 md:px-10 mt-6">
      <div className="flex flex-col w-full gap-12 lg:flex-row">
        {/* Left column */}
        <div className="flex flex-col w-full gap-12 lg:w-2/3">
          <div className="flex flex-col w-full gap-6">
            <p className="text-2xl font-medium text-onSurface-light dark:text-onSurface-dark">
              {GamesInfo[gameName].title} Model Insights
            </p>
            <ModelInfo
              Rating={model.rating}
              Name={model.name}
              DateCreated={new Date(model.dateCreated).toLocaleDateString()}
              Creator={model.username}
            />
          </div>
          <div className="flex flex-col w-full gap-6">
            <p className="text-2xl font-medium text-onSurface-light dark:text-onSurface-dark">
              Code Submission
            </p>
            {model.codebroke && (
              <p className="text-red-500">This code causes errors.</p>
            )}
            <div className="w-full overflow-x-auto">
              <SubmissionCode code={base64_decode(model.files[0].contents)} />
            </div>
          </div>
        </div>

        {/* Right column */}
        <div className="flex flex-col w-full gap-12 lg:w-1/3 p-2">
          <div className="flex flex-col w-full gap-6">
            <p className="text-2xl font-medium text-onSurface-light dark:text-onSurface-dark">
              Rating Over Time
            </p>
            <div className="p-4 rounded-lg border-border-light dark:border-border-dark border-[1px] dark:bg-surfaceContain-dark bg-surfaceContain-light">
              <ProgressGraph
                history={history}
                modelId={modelId}
                dateCreated={model.dateCreated}
                initialRating={model.rating}
              />
            </div>
          </div>
          <div className="flex flex-col w-full gap-6">
            <p className="text-2xl font-medium text-onSurface-light dark:text-onSurface-dark">
              Rated Battles
            </p>
            {history ? (
              <div className="border-border-light overflow-clip border-[1px] rounded-lg dark:border-border-dark max-h-96 overflow-y-auto">
                {history.toReversed().map((battle, index) => (
                  <RecentBattleCard
                    key={index}
                    data={battle}
                    modelId={modelId}
                    setShowBattle={setShowBattle}
                  />
                ))}
              </div>
            ) : (
              <div className="text-center">No past battles...</div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

export default SubmissionPage;
