import React, { useState } from "react";
import { auth } from "../../Firebase/firebaseAuth";
import { createUserWithEmailAndPassword } from "../../Firebase/firebaseAuth";

const Register = ({ setMode }) => {
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [errorTxt, setErrorTxt] = useState("");

    const createEmailAccount = async (email, password) => {
        createUserWithEmailAndPassword(auth, email, password)
            .then(() => {
                setErrorTxt("");
            })
            .catch((error) => {
                console.error("Error:", error);
                if (error.toString().includes("email-already-in-use")) {
                    setErrorTxt("A user with that email already exists.");
                } else if (error.toString().includes("invalid-email")) {
                    setErrorTxt("Invalid email.");
                } else {
                    setErrorTxt(error.toString());
                }
            });
    };

    const handleEmailChange = (event) => {
        setEmail(event.target.value);
    };
    
    const handlePasswordChange = (event) => {
        setPassword(event.target.value);
    };

    const handleCreateAccountBtnClick = () => {
        createEmailAccount(email, password);
    };

    const navigateToLogin = () => {
        setErrorTxt("");
        setMode("login");
    };

    const registerEnabled = email !== "" && password !== "";

    return (
        <div className="login-page flex justify-center items-center w-full h-[calc(100vh-120px)]">
            <div className="login-container w-[500px] bg-surfaceContain-light dark:bg-surfaceContain-dark rounded-lg shadow-md p-6">
                <h2 className="login-title font-bold text-4xl text-center select-none text-onSurface-light dark:text-onSurface-dark">Register</h2>
                <div className="email-input flex justify-center mt-7">
                    <input
                        type="email"
                        required
                        className="w-full p-2 border rounded-md shadow-sm border-border-light dark:border-border-dark bg-surface-light dark:bg-surface-dark text-onSurface-light dark:text-onSurface-dark focus:outline-none focus:ring-2 focus:ring-primary-light dark:focus:ring-primary-dark focus:border-primary-light dark:focus:border-primary-dark"
                        placeholder="Email"
                        value={email}
                        onChange={handleEmailChange}
                    />
                </div>
                <div className="password-input flex justify-center mt-4">
                    <input
                        type="password"
                        required
                        className="w-full p-2 border rounded-md shadow-sm border-border-light dark:border-border-dark bg-surface-light dark:bg-surface-dark text-onSurface-light dark:text-onSurface-dark focus:outline-none focus:ring-2 focus:ring-primary-light dark:focus:ring-primary-dark focus:border-primary-light dark:focus:border-primary-dark"
                        placeholder="Password"
                        value={password}
                        onChange={handlePasswordChange}
                    />
                </div>
                {errorTxt && <div className="error-txt text-red-500 text-sm mt-2">{errorTxt}</div>}
                <div className="login-btn mt-7">
                    <button
                        className={`w-full py-2 rounded-md transition-colors duration-200 bg-primary-light dark:bg-primary-dark hover:bg-primary-darkHover dark:hover:bg-primary-lightHover ${
                            !registerEnabled ? "bg-gray-300 cursor-not-allowed" : ""
                          }`}
                        onClick={handleCreateAccountBtnClick}
                        disabled={!registerEnabled}
                    >
                        Create Account
                    </button>
                </div>
                <div className="under-txt-container flex justify-center mt-7">
                    <span
                        className="mt-2 text-sm transition duration-100 cursor-pointer select-none under-txt text-onSurfaceSecond-light dark:text-onSurfaceSecond-dark hover:brightness-75"
                        onClick={navigateToLogin}
                    >
                        Back to Login
                    </span>
                </div>
            </div>
        </div>
    );
};

export default Register;
